@font-face {
  font-family: "Nunito Sans";
  src: url("../fonts/NunitoSans/NunitoSans-VariableFont_YTLC.ttf");
}
:root {
  --color-inovyo: #9a2376;
  --color-inovyo-light: #b44292;
  --color-inovyo-dark: #7b0e5a;
  --color-bg: #f0f2f5;
  --color-inovyo-green: #009045;
  --color-inovyo-yellow: #ffc400;
  --color-inovyo-yellow-light: #ffd23e;
  --color-inovyo-yellow-dark: #d1a100;
  --radius-small: 5px;
  --radius: 8px;
  --radius-large: 12px;
  --padding-small: 5px;
  --padding: 10px;
  --padding-large: 10px;
}

::-webkit-scrollbar {
  width: 10px;
  border-radius: 5px;
}

/* ::-webkit-scrollbar-button { } */
::-webkit-scrollbar-track {
  background-color: #eeeeee;
  border-radius: 5px;
}

/* ::-webkit-scrollbar-track-piece {} */
::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 5px;
}

/* ::-webkit-scrollbar-corner {} */
/* ::-webkit-resizer {} */
*,
html,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

::selection {
  background-color: var(--color-inovyo);
  color: #ffffff;
}

body {
  font-family: "Nunito Sans";
  -webkit-text-size-adjust: 100%;
  color: #444;
  overflow-y: auto;
  background-color: var(--color-bg);
  font-size: 0.8rem;
}

ul {
  list-style: none;
}
