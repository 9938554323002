#footer-page {
  text-align: center;
}
#footer-page img {
  width: 30px;
  display: inline;
}
#footer-page > span {
  color: #aaa;
}
