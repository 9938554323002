@keyframes gradientbrain {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
#home-page {
  padding: 5px;
}
#home-page div.in-search-brain {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #7623d5, #d5c323);
  background-size: 400% 400%;
  animation: gradientbrain 1s ease infinite;
  color: white;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
}
#home-page div.inline-filters {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 5px;
}
#home-page div.inline-filters div.options {
  flex: 1;
}
#home-page div.content {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
}
#home-page div.content > div.item {
  width: calc(33.3333333333% - 7px);
  border-radius: 5px;
  position: relative;
}
#home-page div.content > div.item > div.more-options {
  height: 30px;
  width: 30px;
  float: right;
  right: 0px;
  top: 5px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}
#home-page div.content > div.item a {
  text-decoration: none;
  outline: none;
  display: block;
  overflow: hidden;
  width: calc(100% - 20px);
  color: #222;
}
#home-page div.content > div.item a > div.icon,
#home-page div.content > div.item a > div.description {
  position: relative;
  float: left;
  left: 0;
  top: 0;
}
#home-page div.content > div.item a > div.description {
  height: 60px;
  left: 10px;
  width: calc(100% - 70px);
}
#home-page div.content > div.item a > div.description > div.dash-name,
#home-page div.content > div.item a > div.description > div.dash-name-inactive {
  height: 45px;
  font-weight: bold;
  width: calc(100% - 1px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
}
#home-page div.content > div.item a > div.description > div.dash-name-inactive {
  color: #999;
}
#home-page div.content > div.item a > div.description > div.dash-type {
  color: #aaa;
  font-weight: bold;
  font-size: 10px;
  width: calc(100% - 1px);
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: hidden;
}
#home-page div.content > div.item a > div.icon {
  width: 60px;
  height: 60px;
}
#home-page div.content > div.item a > div.icon > img {
  width: 60px;
}

@media all and (max-width: 768px) {
  #home-page div.content > div.item {
    width: calc(100% - 7px);
  }
}
