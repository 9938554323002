div#new-pass-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 70px);
}
div#new-pass-page div.logo {
  width: 300px;
  text-align: center;
}
div#new-pass-page div.logo img {
  width: 180px;
}
div#new-pass-page div.no-permission {
  text-align: center;
  font-size: 40px;
  font-weight: bold;
}
div#new-pass-page div.no-permission span {
  display: block;
  color: var(--color-inovyo);
  font-size: 20px;
}
