#send-bases-page .delete-box {
  border: solid 5px #d3b3c9;
  border-radius: 10px;
  padding: 10px;
  font-weight: bold;
  width: 100%;
  max-width: 900px;
  /* antd */
}
#send-bases-page .delete-box span.anticon-delete {
  float: right;
  position: relative;
  right: -9px;
  top: -9px;
  background-color: #ffcaca;
  border-radius: 6px;
  padding: 10px;
  cursor: pointer;
}
#send-bases-page .delete-box span.anticon-delete > svg {
  font-size: 20px;
}
#send-bases-page .delete-box span.anticon-delete:hover {
  color: red;
}
