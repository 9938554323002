#loading-page {
  display: flex;
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 3500;
  top: 0;
  left: 0;
  float: left;
  background-color: white;
  align-items: center;
  justify-content: center;
}
#loading-page > img {
  height: 70px;
  width: 70px;
  position: absolute;
}
