div#recover-pass-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 70px);
}
div#recover-pass-page div.logo {
  width: 300px;
  text-align: center;
}
div#recover-pass-page div.logo img {
  width: 180px;
}
