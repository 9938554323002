#login-page {
  height: calc(100vh - 140px);
  display: flex;
  justify-content: center;
  align-items: center;
}
#login-page .login-box {
  width: 300px;
  margin: auto;
}
#login-page .login-box .logo-login {
  height: 70px;
  background-image: url("../../images/logo-inovyo.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 195px;
}
#login-page .login-box .login-google {
  border: solid 1px #ddd;
  border-radius: 5px;
  padding: 10px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#login-page .login-box .login-google > img {
  height: 20px;
}
#login-page .login-box .login-google > a {
  text-decoration: none;
  outline: none;
  color: #444;
  font-weight: 600;
  margin-left: 10px;
}
