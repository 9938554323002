@keyframes blinker {
  50% {
    opacity: 0;
  }
}
header#header-inovyo {
  width: 100%;
  height: 60px;
  background-color: #fff;
  position: fixed;
  z-index: 1000;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 5px;
}
header#header-inovyo > .logo,
header#header-inovyo > .menu-small-horizontal,
header#header-inovyo > .magic-button {
  position: relative;
  float: left;
  left: 0;
  top: 0;
  height: 60px;
}
header#header-inovyo > .magic-button {
  display: flex;
  justify-items: center;
  align-items: center;
  left: 10px;
}
header#header-inovyo > .logo {
  height: 60px;
}
header#header-inovyo > .logo > a {
  display: block;
  padding: 5px;
}
header#header-inovyo > .logo > a img {
  width: 40px;
}
header#header-inovyo > .options,
header#header-inovyo > .options2 {
  position: relative;
  float: right;
  right: 0;
  top: 0;
  height: 60px;
  width: auto;
  min-width: 100px;
  /* antd */
}
header#header-inovyo > .options > ul.ant-menu > li div.ant-menu-submenu-title,
header#header-inovyo > .options2 > ul.ant-menu > li div.ant-menu-submenu-title {
  height: 60px;
  line-height: 60px;
}
header#header-inovyo > .options > div.notifcation-inovyo,
header#header-inovyo > .options > div.menu-inovyo,
header#header-inovyo > .options2 > div.notifcation-inovyo,
header#header-inovyo > .options2 > div.menu-inovyo {
  line-height: 64px;
  display: flex;
  float: right;
  clear: both;
  height: 60px;
  width: 60px;
  position: absolute;
  justify-content: center;
}
header#header-inovyo > .options > div.notifcation-inovyo > span,
header#header-inovyo > .options > div.menu-inovyo > span,
header#header-inovyo > .options2 > div.notifcation-inovyo > span,
header#header-inovyo > .options2 > div.menu-inovyo > span {
  line-height: 64px;
}
header#header-inovyo > .options > div.notifcation-inovyo > span > span,
header#header-inovyo > .options > div.notifcation-inovyo > span > svg,
header#header-inovyo > .options > div.menu-inovyo > span > span,
header#header-inovyo > .options > div.menu-inovyo > span > svg,
header#header-inovyo > .options2 > div.notifcation-inovyo > span > span,
header#header-inovyo > .options2 > div.notifcation-inovyo > span > svg,
header#header-inovyo > .options2 > div.menu-inovyo > span > span,
header#header-inovyo > .options2 > div.menu-inovyo > span > svg {
  cursor: pointer;
}
header#header-inovyo > .options > div.notifcation-inovyo > span > sup,
header#header-inovyo > .options > div.menu-inovyo > span > sup,
header#header-inovyo > .options2 > div.notifcation-inovyo > span > sup,
header#header-inovyo > .options2 > div.menu-inovyo > span > sup {
  top: 15px;
  animation: blinker 1s linear infinite;
  width: 10px;
  height: 10px;
}
header#header-inovyo > .options > div.notifcation-inovyo,
header#header-inovyo > .options2 > div.notifcation-inovyo {
  right: 60px;
}
header#header-inovyo > .options > div.menu-inovyo,
header#header-inovyo > .options2 > div.menu-inovyo {
  right: 0px;
}
header#header-inovyo > .menu-small-horizontal {
  left: 20px;
}
header#header-inovyo > .menu-small-horizontal > a {
  display: inline-flex;
  height: 60px;
  padding: 10px;
  text-decoration: none;
  outline: none;
  color: #222;
  justify-content: center;
  align-items: center;
  border-bottom: solid 2px transparent;
}
header#header-inovyo > .menu-small-horizontal > a:hover {
  border-bottom-color: var(--color-inovyo);
  background-image: linear-gradient(to top, #f3f3f3, #fff);
}
header#header-inovyo > .menu-small-horizontal > a.selected {
  border-bottom-color: var(--color-inovyo);
}
header#header-inovyo > .logo {
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
header#header-inovyo > .logo > a {
  text-decoration: none;
  outline: none;
  display: block;
  width: 45px;
  height: 45px;
}

div#menu-small-drawer {
  /* Antd*/
}
div#menu-small-drawer .ant-drawer-body {
  padding: 10px;
}
div#menu-small-drawer .menu-small-vertical > a {
  display: block;
  padding: 5px;
  text-decoration: none;
  outline: none;
  color: #222;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
div#menu-small-drawer .menu-small-vertical > a:hover {
  background-color: #ddd;
}
div#menu-small-drawer .menu-small-vertical > a.selected {
  background-color: var(--color-inovyo);
  color: white;
}

div#magic-menu {
  background-color: var(--color-inovyo);
  border: none;
  overflow: hidden;
  /*antd*/
  /*antd*/
}
div#magic-menu div.ant-list-header {
  padding-top: 16px;
  background-color: white;
  display: inline-block;
  width: 100%;
  line-height: 20px;
}
div#magic-menu div.ant-list-header > img {
  display: inline;
}
div#magic-menu li.ant-list-item {
  color: white;
  cursor: pointer;
}
div#magic-menu li.ant-list-item:hover {
  background-color: var(--color-inovyo-light);
}

@media all and (max-width: 750px) {
  header#header-inovyo > .menu-small-horizontal {
    display: none;
  }
  header#header-inovyo > .options {
    display: block;
  }
  header#header-inovyo > .options2 {
    display: none;
  }
}
@media all and (min-width: 751px) {
  header#header-inovyo > .menu-small-horizontal {
    display: block;
  }
  header#header-inovyo > .options {
    display: none;
  }
  header#header-inovyo > .options2 {
    display: block;
  }
}
